<template>
  <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="20"
      height="20.36"
      rx="3"
      fill="#EFF3F3"
    />
    <path d="M3 12.2648V8.09534H4.1497V12.2648H3Z" fill="#00785F" />
    <path
      d="M8.26333 9.72245C9.03177 9.72245 9.60093 10.1454 9.60093 10.9911C9.60093 11.6881 9.08864 12.2659 8.26333 12.2659H5.03125V8.09534H9.10115V9.11981H6.18095V9.72245H8.26333ZM8.12115 11.301C8.33157 11.301 8.45123 11.2107 8.45123 10.9911C8.45123 10.7715 8.33157 10.6876 8.12115 10.6876H6.17526V11.301H8.12115Z"
      fill="#00785F"
    />
    <path
      d="M10.1875 12.2648V8.09534H11.3363V12.2648H10.1875Z"
      fill="#00785F"
    />
    <path
      d="M15.8591 10.6624C15.7681 11.0856 15.2055 11.3355 14.4964 11.3355C13.6254 11.3355 13.1315 10.8768 13.1315 10.1799C13.1315 9.48288 13.6265 9.02448 14.4964 9.02448C15.2016 9.02448 15.766 9.27275 15.857 9.69753H17.0008C16.8893 8.6638 15.9626 8 14.4964 8C12.9311 8 11.9805 8.86355 11.9805 10.1799C11.9805 11.4962 12.9311 12.36 14.4964 12.36C15.9626 12.36 16.8893 11.6962 17.0008 10.6624H15.8591Z"
      fill="#00785F"
    />
  </svg>
</template>
