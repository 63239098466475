<template>
  <button
    v-if="!currentVariant?.archived"
    type="button"
    class="md:w-fit w-full bg-gray-mud text-white font-normal leading-5 !px-7 py-1 mt-2 md:mt-5 flex items-center"
    @click="openNotifyMeDialog"
  >
    <IconsMail class="w-[30px]" />
    <span class="text-[16px]">{{ t('Повідомити про наявність') }}</span>

    <AppDialogsEmailSubmission v-model="notifyMeDialog" type="notify-me" />
  </button>
</template>
<script setup>
const notifyMeDialog = ref(null)

const { t } = useI18n()
const props = defineProps({
  currentVariant: {
    type: Object,
    default: () => {}
  }
})
const openNotifyMeDialog = () => {
  notifyMeDialog.value?.open({
    name: props.currentVariant?.name,
    code: props.currentVariant?.code
  })
}
</script>
