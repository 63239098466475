<template>
  <div v-if="currentVariant.isOrdered" class="lg:mt-4">
    <ProductSectionsCommonAmountPicker v-model="amount" />
    <button
      id="btn-add-to-cart-preorder"
      v-loading="loading"
      type="button"
      class="bg-green-ibis !px-5 mt-4"
      :disabled="loading"
      @click="addToPreorder"
    >
      <span>{{ t('Передзамовити') }}</span>
    </button>
  </div>
  <div
    v-else-if="currentVariant.inStock"
    class="grid grid-cols-[auto_1fr] gap-x-2.5 gap-y-4 lg:flex lg:items-start lg:gap-x-4 lg:mt-5"
  >
    <ProductSectionsCommonAmountPicker v-model="amount" />
    <button
      id="btn-add-to-cart"
      v-loading="loading"
      type="button"
      class="btn-primary !px-5"
      :disabled="!currentVariant.price || loading"
      @click="addToCart"
    >
      <IconsButtonCart class="w-4 h-4" />
      <span>{{
        currentVariant?.isSpecorder ? t('specorder_btn') : t('Купити')
      }}</span>
    </button>
    <div class="lg:hidden flex items-start gap-1">
      <nuxt-img
        format="webp"
        width="28"
        height="28"
        src="/images/instalment/privat-bank.png"
        loading="lazy"
      />
      <nuxt-img
        format="webp"
        height="28"
        width="28"
        src="/images/instalment/mono.png"
        loading="lazy"
      />
      <nuxt-img
        format="webp"
        height="28"
        width="28"
        src="/images/instalment/a-bank.png"
        loading="lazy"
      />
      <nuxt-img
        format="webp"
        height="28"
        width="28"
        src="/images/instalment/pay-later.jpg"
        loading="lazy"
      />
    </div>
    <div v-if="partMethods.length" class="flex flex-col flex-1">
      <button
        type="button"
        class="btn-green !px-5 whitespace-nowrap lg:flex-auto"
        :disabled="!currentVariant.price"
        @click="openInstallmentsDialog"
      >
        {{ t('Купити в кредит') }}*
      </button>
      <div class="text-[11px] text-gray-mud mt-2">
        *{{ t('при замовленні від') }} 2000 грн
      </div>
    </div>
    <div v-else class="flex flex-col flex-1"></div>
  </div>
  <AppAvailabilityNotificationButton v-else :current-variant="currentVariant" />
  <AppDialogsBuyInInstallments
    v-model="installmentsDialog"
    :part-methods="partMethods"
    :add-to-cart="addToCart"
  />
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useCartStore } from '~/stores/cart'
import { usePreorderStore } from '~/stores/preorder'
import { usePaymentStore } from '~/stores/payment'

const { t } = useI18n()
const currentVariant = inject('currentVariant')
const amount = ref(1)
const loading = ref(false)
const { partMethods } = storeToRefs(usePaymentStore())

const installmentsDialog = ref(null)

const openInstallmentsDialog = () => {
  installmentsDialog.value?.open(currentVariant.value.price / 100)
}

const addToCart = async (openCart = true, setLoading = true) => {
  try {
    loading.value = setLoading
    await useCartStore().addVariantToCart(
      currentVariant.value.code,
      amount.value
    )
    if (openCart) {
      return useCartStore().openCart()
    }
  } finally {
    loading.value = false
  }
}

const addToPreorder = async () => {
  try {
    loading.value = true
    await usePreorderStore().addVariantToCart(
      currentVariant.value.code,
      amount.value
    )
  } catch (err) {
    console.error(err)
  } finally {
    loading.value = false
  }
}
</script>

<style lang="postcss" scoped>
button {
  @apply flex btn items-center gap-2.5 h-11 flex-1 text-white justify-center text-xs uppercase;
}
</style>
<style>
#btn-add-to-cart .vl-icon svg {
  width: 35px;
}
</style>
