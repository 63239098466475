<template>
  <svg viewBox="0 0 25 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8235 4.88542V7.81668H10.9004V0.577757H13.3488C13.641 0.571607 13.9316 0.62382 14.2037 0.73138C14.4758 0.838941 14.7241 0.999721 14.9342 1.20444C15.1464 1.39673 15.3153 1.63256 15.4295 1.89606C15.5438 2.15957 15.6007 2.44466 15.5964 2.73216C15.6025 3.02121 15.5465 3.30815 15.4322 3.57334C15.3179 3.83853 15.148 4.07568 14.9342 4.26855C14.506 4.67979 13.9776 4.88522 13.3488 4.88484H11.8235V4.88542ZM11.8235 1.46898V3.99593H13.3717C13.5414 4.001 13.7103 3.97033 13.8676 3.90588C14.0249 3.84143 14.1671 3.74462 14.2851 3.62165C14.4025 3.50667 14.4958 3.36915 14.5595 3.21722C14.6233 3.06528 14.6561 2.90201 14.6561 2.73707C14.6561 2.57213 14.6233 2.40887 14.5595 2.25693C14.4958 2.10499 14.4025 1.96747 14.2851 1.8525C14.1686 1.72693 14.0269 1.62775 13.8694 1.56154C13.7119 1.49532 13.5423 1.46357 13.3717 1.4684H11.8235V1.46898Z"
      fill="#464646"
    />
    <path
      d="M17.7238 2.70227C18.4061 2.70227 18.9447 2.88594 19.3396 3.25329C19.7344 3.62064 19.9317 4.12429 19.9313 4.76426V7.81682H19.0483V7.12949H19.0082C18.6259 7.69553 18.1175 7.97855 17.4829 7.97855C16.9413 7.97855 16.4881 7.81682 16.1234 7.49337C15.9484 7.34492 15.8085 7.159 15.7139 6.94916C15.6192 6.73933 15.5723 6.51087 15.5764 6.28043C15.5764 5.76792 15.7687 5.36033 16.1533 5.05768C16.5378 4.75502 17.0512 4.60331 17.6934 4.60254C18.2415 4.60254 18.693 4.70362 19.0477 4.90577V4.69322C19.0488 4.53613 19.0151 4.38077 18.9491 4.23841C18.8832 4.09605 18.7866 3.97026 18.6664 3.87016C18.4223 3.64829 18.1041 3.52723 17.7754 3.53111C17.2597 3.53111 16.8516 3.75021 16.5512 4.18841L15.7381 3.67262C16.1854 3.02572 16.8472 2.70227 17.7238 2.70227ZM16.5294 6.30065C16.5288 6.419 16.5562 6.53578 16.6095 6.64128C16.6628 6.74677 16.7403 6.83791 16.8356 6.90712C17.0398 7.0689 17.2932 7.1546 17.5529 7.14971C17.9424 7.14905 18.3159 6.99286 18.5913 6.71536C18.8971 6.42541 19.05 6.08521 19.05 5.69476C18.7622 5.46372 18.3608 5.34821 17.8459 5.34821C17.4709 5.34821 17.1582 5.43927 16.9078 5.62141C16.655 5.80623 16.5294 6.03092 16.5294 6.30065V6.30065Z"
      fill="#464646"
    />
    <path
      d="M24.9986 2.86389L21.9161 10H20.9631L22.107 7.50308L20.0801 2.86389H21.0835L22.5485 6.42184H22.5686L23.9935 2.86389H24.9986Z"
      fill="#464646"
    />
    <path
      d="M8.09331 4.25367C8.09366 3.97028 8.06987 3.68738 8.02221 3.40808H4.12891V5.00974H6.35881C6.31318 5.26554 6.2166 5.50939 6.07489 5.72659C5.93319 5.94378 5.74929 6.12983 5.53428 6.2735V7.31316H6.86511C7.64434 6.58944 8.09331 5.51917 8.09331 4.25367Z"
      fill="#4285F4"
    />
    <path
      d="M4.12806 8.31756C5.24215 8.31756 6.18021 7.94905 6.86427 7.31371L5.53343 6.27405C5.16302 6.52703 4.68597 6.67143 4.12806 6.67143C3.05124 6.67143 2.13726 5.9402 1.81042 4.95483H0.439453V6.02626C0.783066 6.71502 1.30996 7.29403 1.96131 7.69863C2.61265 8.10324 3.36282 8.31752 4.12806 8.31756V8.31756Z"
      fill="#34A853"
    />
    <path
      d="M1.81057 4.95477C1.63779 4.43843 1.63779 3.87927 1.81057 3.36293V2.2915H0.439601C0.150542 2.87091 0 3.51039 0 4.15885C0 4.80731 0.150542 5.44678 0.439601 6.0262L1.81057 4.95477Z"
      fill="#FBBC04"
    />
    <path
      d="M4.12806 1.6464C4.71681 1.63671 5.28569 1.86079 5.71176 2.2702L6.89007 1.08325C6.1429 0.376327 5.15306 -0.0117867 4.12806 0.000272846C3.36282 0.000307978 2.61265 0.21459 1.96131 0.619196C1.30996 1.0238 0.783066 1.60281 0.439453 2.29157L1.81042 3.363C2.13726 2.37763 3.05124 1.6464 4.12806 1.6464Z"
      fill="#EA4335"
    />
  </svg>
</template>
