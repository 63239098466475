<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="20"
      height="20"
      rx="3"
      fill="#464646"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.09577 11.0387V9.07254C7.09577 8.51479 6.79718 8.25 6.29577 8.25C5.87887 8.25 5.51831 8.49789 5.36056 8.74577C5.25916 8.43592 5.00563 8.25 4.60563 8.25C4.18873 8.25 3.82817 8.50915 3.71549 8.67254V8.31761H3V11.0387H3.71549V9.20775C3.82254 9.05563 4.03099 8.88662 4.27887 8.88662C4.57183 8.88662 4.68451 9.0669 4.68451 9.32042V11.0387H5.40563V9.20211C5.50704 9.05563 5.71549 8.88662 5.96901 8.88662C6.26197 8.88662 6.37465 9.0669 6.37465 9.32042V11.0387H7.09577ZM9.06197 11.1063C8.1662 11.1063 7.62535 10.4528 7.62535 9.67535C7.62535 8.90352 8.1662 8.25 9.06197 8.25C9.96338 8.25 10.5042 8.90352 10.5042 9.67535C10.5042 10.4528 9.96338 11.1063 9.06197 11.1063ZM9.06197 10.4697C9.50704 10.4697 9.76056 10.1035 9.76056 9.67535C9.76056 9.25282 9.50704 8.88662 9.06197 8.88662C8.6169 8.88662 8.36901 9.25282 8.36901 9.67535C8.36901 10.1035 8.6169 10.4697 9.06197 10.4697ZM13.5859 11.0387V9.11761C13.5859 8.58803 13.2986 8.25 12.7014 8.25C12.2563 8.25 11.9239 8.46408 11.7493 8.67254V8.31761H11.0338V11.0387H11.7493V9.20775C11.8676 9.04437 12.0873 8.88662 12.369 8.88662C12.6732 8.88662 12.8704 9.0162 12.8704 9.39366V11.0387H13.5859ZM15.5577 11.1063C14.662 11.1063 14.1211 10.4528 14.1211 9.67535C14.1211 8.90352 14.662 8.25 15.5577 8.25C16.4592 8.25 17 8.90352 17 9.67535C17 10.4528 16.4592 11.1063 15.5577 11.1063ZM15.5577 10.4697C16.0028 10.4697 16.2563 10.1035 16.2563 9.67535C16.2563 9.25282 16.0028 8.88662 15.5577 8.88662C15.1127 8.88662 14.8648 9.25282 14.8648 9.67535C14.8648 10.1035 15.1127 10.4697 15.5577 10.4697Z"
      fill="white"
    />
  </svg>
</template>
