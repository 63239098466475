<template>
  <Disclosure
    v-slot="{ open }"
    :default-open="true"
    as="div"
    class="mt-2 lg:mt-6"
  >
    <div class="w-full py-2 flex text-[15px] font-bold">
      <span>{{ t('Гарантія') }} : {{ unitValue }}</span>
      <DisclosureButton as="template" class="">
        <IconsDropdownChevron
          :active="open"
          active-class="!text-gray-mud"
          class="ml-auto"
        />
      </DisclosureButton>
    </div>
    <DisclosurePanel>
      <div class="font-normal text-[12px] mt-2.5">
        <nuxt-link
          :to="localePath('/obmin-ta-povernennya/')"
          class="font-normal link-underline"
        >
          {{ t('Детальніше про гарантію та повернення') }}
        </nuxt-link>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
const currentVariant = inject('currentVariant')
const localePath = useLocalePath()
const { t } = useI18n()
const unitValue = computed(() => {
  return (
    currentVariant.value?.attributes?.find(({ code }) => code === 'garantiia')
      ?.value || t('14 днів')
  )
})
</script>
