<template>
  <div :key="'variant-' + variant.id" class="p-2.5 border-b flex flex-col">
    <div
      class="p-2.5 pt-3 pb-6 flex items-center justify-center my-auto min-h-[158px]"
    >
      <nuxt-img
        v-if="image"
        :src="image"
        width="130"
        class="max-h-[140px] object-contain"
        loading="lazy"
      />
      <nuxt-img
        v-else
        :src="NO_IMAGE_PATH"
        width="130"
        format="webp"
      />
    </div>
    <div class="text-[12px] font-semibold line-clamp-2 mb-2">
      {{ variant.name }}
    </div>
    <div class="text-[15px] font-bold">
      {{ getPrice(variant?.originalPrice / 100) }}
    </div>
    <div class="flex gap-1">
      <AppReviewRating :rating="variant?.averageRating" />
      <span class="text-[12px] text-gray-mud">
        ({{ variant?.totalReviews }})
      </span>
    </div>
  </div>
</template>

<script setup>
import { NO_IMAGE_PATH } from '~~/utils/constants'
const getPrice = usePriceFormatter()
const props = defineProps({
  variant: {
    type: Object,
    default: () => {}
  }
})

const { data: image } = useLazyAsyncData(
  props.variant?.code + '-main-image',
  () => useAssetUrl(props.variant?.images[0].path)
)
</script>
