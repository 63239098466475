<template>
  <div v-if="product.promotions && product.promotions.length" class="bg-white">
    <ListHorizontal
      base-slide-width="560"
      :title="t('Разом дешевше')"
      :hide-scrollbar="true"
      header-class=" pb-2 !mb-0 text-xs"
      title-class="text-lg text-black font-bold"
      :space-between="20"
      :slides="product.promotions"
      slide-class="border-t border-gray border-r border-l"
      :slide-per-view="1"
      :breakpoints="{
        600: {
          slidePerView: 1
        }
      }"
    >
      <template #default="{ slide: specialOfferProduct }">
        <div class="relative grid grid-cols-2 divide-x">
          <ProductItem :variant="specialOfferProduct.originalVariant" />
          <ProductItem :variant="specialOfferProduct.discountedVariants[0]" />
        </div>
        <div
          class="absolute h-9 w-9 flex items-center justify-center bg-green-ibis rounded-full left-1/2 top-1/4 -translate-x-[50%]"
        >
          <IconsProductPlus class="h-4 w-4 text-white" />
        </div>
        <div
          class="absolute h-5 px-1 flex items-center justify-center bg-primary text-white text-xs left-0 top-0 uppercase font-bold"
        >
          -{{ specialOfferProduct.percentage }}% на комплект
        </div>
        <div class="flex p-5 gap-2.5 justify-between lg:justify-end">
          <div>
            <div class="space-x-1 text-[12px]">
              <span class="line-through decoration-primary">{{
                getPrice(specialOfferProduct?.originalTotalPrice / 100)
              }}</span>
              <span class="px-1.5 bg-red-hot/20">-{{ specialOfferProduct.percentage }}%</span>
            </div>
            <div class="font-bold text-[19px] text-primary">
              {{ getPrice(specialOfferProduct?.discountedTotalPrice / 100) }}
            </div>
          </div>
          <!--          <button-->
          <!--            v-if="currentVariant?.isOrdered"-->
          <!--            type="button"-->
          <!--            class="flex btn items-center gap-2.5 h-11 text-white bg-green-ibis justify-center px-5 text-xs uppercase"-->
          <!--            @click="addToPreorder"-->
          <!--          >-->
          <!--            <IconsButtonCart class="w-4 h-4" />-->
          <!--            <span>{{ t('Передзамовити') }}</span>-->
          <!--          </button>-->
          <button
            type="button"
            class="flex btn items-center gap-2.5 h-11 text-white bg-primary justify-center px-5 text-xs uppercase"
            @click="addToCart(specialOfferProduct)"
          >
            <IconsButtonCart class="w-4 h-4" />
            <span>{{ t('Купити') }}</span>
          </button>
        </div>
      </template>
    </ListHorizontal>
  </div>
</template>

<script setup>
import ProductItem from './ProductItem.vue'
// import { usePreorderStore } from '~/stores/preorder'

const { t } = useI18n()
const getPrice = usePriceFormatter()
const product = inject('product')

// const addToPreorder = async () => {
//   try {
//     await usePreorderStore().addVariantToCart(currentVariant.value.code)
//   } catch (err) {
//     console.error(err)
//   }
// }

const addToCart = async promotion => {
  await useCartStore().addMultipleVariantsToCart([
    {
      code: promotion?.originalVariant?.code,
      quantity: 1
    },
    {
      code: promotion?.discountedVariants[0]?.code,
      quantity: 1
    }
  ])
  return useCartStore().openCart()
}
</script>
