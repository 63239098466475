<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.25"
      y="2.54846"
      width="17.5"
      height="15"
      rx="2.5"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M1.35547 7.66052H18.6895"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.16797 12.9657H8.66797"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
