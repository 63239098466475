<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="close">
      <AppDialogsCommonBackdrop />
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex md:min-h-fit min-h-full justify-center text-center p-0"
        >
          <AppDialogsCommonPanelTransition>
            <DialogPanel
              class="transform rounded-0 bg-white p-3.5 my-0 text-left transition-all min-h-full w-full relative md:my-44 md:rounded-md md:w-[750px] md:p-5"
            >
              <div class="flex mb-4">
                <h2 class="text-sm md:text-xl font-bold">
                  {{ t('Купити в розстрочку') }}
                </h2>
                <AppDialogsCommonCloseButton class="ml-auto" @click="close" />
              </div>

              <div class="flex flex-col gap-5">
                <Item
                  v-for="item in props.partMethods"
                  :key="item.code"
                  :item="item"
                  :price="price"
                  @submit="submit"
                >
                  <!-- <component :is="item.icon" class="w-[26px] self-start" /> -->
                </Item>
              </div>
            </DialogPanel>
          </AppDialogsCommonPanelTransition>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionRoot } from '@headlessui/vue'
import Item from './Item.vue'
import { useAuthStore } from '~/stores/auth'

const props = defineProps({
  partMethods: {
    type: Array,
    default: () => []
  },
  addToCart: {
    type: Function,
    default: () => {}
  }
})
const { t } = useI18n()
const emits = defineEmits(['update:modelValue'])
const open = ref(false)
const price = ref(0)
const close = () => {
  open.value = false
}

const submit = async selectedOption => {
  await props.addToCart(false, false)

  navigateTo({
    path: useLocalePath()(
      useAuthStore().loggedInStore ? '/checkout-store/' : '/checkout/'
    ),
    query: {
      code: selectedOption.code,
      partsType: selectedOption.amount.partsType
    }
  })
  // close()
}

onMounted(() => {
  emits('update:modelValue', {
    open: v => {
      open.value = true
      price.value = v
    }
  })
})
</script>
