import Collection from '~/entities/Collection'

export const usePaymentStore = defineStore({
  id: 'payment-store',
  state: () => {
    return {
      methods: []
    }
  },
  actions: {
    loadAllMethods(productCode = {}) {
      return useApi().paymentMethods.getAll(productCode, {
        auth: false,
        onSuccess: res => {
          this.methods = new Collection(res).data
        }
      })
    }
  },
  getters: {
    paymentMethods: state => state?.methods,
    partMethods: state => state.methods.filter(method => method.parts)
  }
})
