<template>
  <svg viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.6557 1.01738H17.3984C17.0182 1.01738 16.7258 1.13434 16.5504 1.51445L14.1528 6.98225H15.8487C15.8487 6.98225 16.1411 6.25126 16.1995 6.07582C16.375 6.07582 18.0416 6.07582 18.2755 6.07582C18.334 6.2805 18.4802 6.95301 18.4802 6.95301H20.0007L18.6557 1.01738ZM16.6674 4.84776C16.8136 4.49689 17.3106 3.18111 17.3106 3.18111C17.3106 3.21035 17.4568 2.83023 17.5153 2.62556L17.6323 3.15187C17.6323 3.15187 17.9539 4.58461 18.0124 4.877H16.6674V4.84776Z"
      fill="#002FBF"
    />
    <path
      d="M14.2699 5.02341C14.2699 6.25147 13.1588 7.07017 11.4337 7.07017C10.7027 7.07017 10.001 6.92397 9.62085 6.74854L9.85476 5.40352L10.0594 5.49124C10.5858 5.72515 10.9366 5.81287 11.5799 5.81287C12.0477 5.81287 12.5448 5.63744 12.5448 5.22808C12.5448 4.96493 12.3401 4.78949 11.6969 4.49709C11.0828 4.2047 10.2641 3.73687 10.2641 2.88892C10.2641 1.71934 11.4045 0.929871 13.0126 0.929871C13.6267 0.929871 14.153 1.04683 14.4746 1.19303L14.2407 2.47957L14.1237 2.36261C13.8313 2.24565 13.4512 2.12869 12.8957 2.12869C12.2816 2.15793 11.9892 2.42109 11.9892 2.655C11.9892 2.91816 12.3401 3.12284 12.8957 3.38599C13.8313 3.82458 14.2699 4.32166 14.2699 5.02341Z"
      fill="#002FBF"
    />
    <path
      d="M7.34033 1.01728L4.79648 6.95291H3.07135L1.60938 1.98219C2.662 2.6547 3.53918 3.70732 3.86082 4.43831L4.03626 5.05234L5.61519 0.988045H7.34033V1.01728Z"
      fill="#002FBF"
    />
    <path
      d="M8.01244 0.988045H9.62062L8.59723 6.95291H6.98906L8.01244 0.988045Z"
      fill="#002FBF"
    />
    <path
      d="M0 1.07613L0.0292395 0.959167H2.54384C2.89471 0.959167 3.15787 1.07613 3.24559 1.45624L3.80114 4.0878C3.24559 2.6843 1.95905 1.54396 0 1.07613Z"
      fill="#FFCF27"
    />
  </svg>
</template>
