<template>
  <div class="flex gap-3 mt-4">
    <a
      id="btn-add-to-favorites"
      class="wrapper"
      href="#"
      @click.prevent="addToFavorites"
    >
      <div class="icon-wrapper">
        <IconsHeart
          class="icon"
          :class="favoriteData?.inWishlist && '!text-primary'"
        />
      </div>
      <span>
        {{ favoriteData?.totalInWishlists || 0 }}
        {{ t('вже додали до обраного') }}
      </span>
    </a>
    <a
      id="btn-add-to-compare"
      class="wrapper"
      href="#"
      @click.prevent="addToCompare"
    >
      <div class="icon-wrapper">
        <IconsComparison
          class="icon"
          :class="{ '!text-primary': inComparison }"
        />
      </div>
      <span v-if="inComparison">{{ t('Вже додано до порівняння') }}</span>
      <span v-else>{{ t('Додати до порівняння') }}</span>
    </a>
    <a
      id="btn-watch-for-price"
      class="wrapper"
      href="#"
      @click.prevent="watchPriceHandler"
    >
      <div class="icon-wrapper">
        <IconsGraph
          class="icon"
          :class="{ '!text-primary': !!watchPriceData?.inWatchPriceList }"
        />
      </div>
      <span v-if="watchPriceData?.inWatchPriceList">
        {{ t('Ціна вже відстежується') }}
      </span>
      <span v-else>{{ t('Стежити за ціною') }}</span>
    </a>
    <AppDialogsWishlist
      v-model="modalWishlists"
      :callback="refreshFavoriteData"
    />
  </div>
</template>

<script setup>
import { useComparisonStore } from '~/stores/comparison'

const { t } = useI18n()

const comparisonStore = useComparisonStore()
const variant = inject('currentVariant')
const product = inject('product')

const { data: favoriteData, refresh: refreshFavoriteData } = useLazyAsyncData(
  `${variant.value.code}-favorite-data`,
  () => useApi().wishlists.getOne(variant.value.code)
)
const { data: watchPriceData, refresh: refreshWatchPriceData } =
  useLazyAsyncData(`${variant.value.code}-watch-price`, () =>
    useApi().productWatchPrice.getVariant(variant.value.code)
  )

const modalWishlists = ref(null)
const openWishlistDialog = () => {
  modalWishlists.value?.open(variant.value)
}

const addToFavorites = () => {
  const options = {
    openModal: openWishlistDialog,
    callback: refreshFavoriteData,
    isFetch: true,
    isInFavorites: favoriteData.value?.inWishlist
  }
  useWishlist(variant.value, options)
}

const inComparison = computed(() =>
  comparisonStore.checkInComparison(variant.value?.code)
)
const addToCompare = () => {
  const payload = {
    ...variant.value,
    product: { ...product.value }
  }
  if (inComparison.value) {
    return comparisonStore.removeProductFromComparison(payload)
  }
  return comparisonStore.addProductToComparison(payload)
}

const watchPriceHandler = () => {
  if (watchPriceData?.value?.inWatchPriceList !== undefined) {
    const { watchPrice, unwatchPrice } = useWatchPrice(variant.value.code, {
      callback: refreshWatchPriceData
    })
    if (watchPriceData.value.inWatchPriceList) {
      return unwatchPrice()
    }
    return watchPrice()
  }
}
</script>

<style lang="postcss" scoped>
.icon {
  @apply text-green-ibis w-[23px] h-[23px] lg:w-[32px] lg:h-[32px];
}
.icon-wrapper {
  @apply h-[43px] w-[43px] border-[1px] border-gray rounded-[5px] flex items-center justify-center lg:border-none;
}
.wrapper {
  @apply text-[12px] underline hover:!text-primary flex flex-1 flex-col items-center gap-3 lg:flex-row text-center md:text-left;
}
.wrapper:hover .icon {
  @apply text-primary;
}
</style>
