<template>
  <div class="flex mt-[18px] items-center gap-[13px]">
    <ProductSectionsCommonPrice
      :price="currentVariant.price / 100"
      :initial-price="currentVariant.originalPrice / 100"
      class="mr-auto"
    />
    <nuxt-img
      v-if="currentVariant.inStock"
      loading="lazy"
      format="webp"
      width="44"
      height="44"
      src="/images/instalment/privat-bank.png"
      class="hidden lg:block"
    />
    <nuxt-img
      v-if="currentVariant.inStock"
      loading="lazy"
      format="webp"
      height="44"
      width="44"
      src="/images/instalment/mono.png"
      class="hidden lg:block"
    />
    <nuxt-img
      v-if="currentVariant.inStock"
      loading="lazy"
      format="webp"
      height="44"
      width="44"
      src="/images/instalment/a-bank.png"
      class="hidden lg:block"
    />
    <nuxt-img
      v-if="currentVariant.inStock"
      loading="lazy"
      format="webp"
      height="44"
      width="44"
      src="/images/instalment/pay-later.jpg"
      class="hidden lg:block"
    />
  </div>
</template>

<script setup>
const currentVariant = inject('currentVariant')
</script>
