<template>
  <div
    v-if="isLargeScreen"
    class="bg-white auto-rows-min grid-cols-2 gap-x-[30px] pl-[41px] pb-6 hidden lg:grid"
  >
    <div>
      <!-- Preview section -->
      <ProductSectionsCommonPreview />

      <!-- Product specification section -->
      <ProductSectionsCommonSpecification />

      <!-- Articles -->
      <LazyProductSectionsCommonArticles />
      <!-- Experts -->
      <client-only>
        <LazyListExpertsReviews />
      </client-only>
    </div>
    <div>
      <div class="pr-8">
        <!-- Title -->
        <ProductSectionsCommonTitle />
        <!-- Cheaper -->
        <ProductSectionsCheaper />
        <!-- Product options -->
        <ProductSectionsCommonOptionsProduct
          @select-variant="onSelectVariant"
        />

        <!-- Availability -->
        <ProductSectionsCommonAvailability />

        <!-- Price Information -->
        <ProductSectionsCommonPriceInfo v-if="!currentVariant.archived" />

        <!-- Buy actions -->
        <ProductSectionsCommonBuyActions v-if="!currentVariant.archived" />

        <!-- Extra actions -->
        <ProductSectionsCommonExtraActions
          v-if="currentVariant.inStock && !currentVariant.archived"
        />

        <!-- Special offer banner-->
        <!--        <ProductSectionsCommonSpecialOffer v-if="currentVariant.inStock && !currentVariant.archived" />-->

        <!-- Shipping -->
        <ProductSectionsCommonShipping
          v-if="currentVariant.inStock && !currentVariant.archived"
        />

        <!-- Payment -->
        <ProductSectionsCommonPayment
          v-if="currentVariant.inStock && !currentVariant.archived"
        />

        <!-- Refund -->
        <ProductSectionsCommonRefund
          v-if="currentVariant.inStock && !currentVariant.archived"
        />
      </div>

      <ListAssociatedProducts
        v-if="!currentVariant.inStock"
        type="similar"
        :product="product"
        slide-width="160"
        class="mt-10"
      />
    </div>
  </div>

  <div v-else class="bg-white block lg:hidden">
    <!-- Title -->
    <ProductSectionsCommonTitle />
    <!-- Preview section -->
    <ProductSectionsCommonPreview />
    <!-- Cheaper -->
    <ProductSectionsCheaper />

    <!-- Product specification section -->
    <ProductSectionsCommonSpecification />

    <!-- Product options -->
    <ProductSectionsCommonOptionsProduct @select-variant="onSelectVariant" />

    <!-- Availability -->
    <ProductSectionsCommonAvailability />

    <!-- Price Information -->
    <ProductSectionsCommonPriceInfo v-if="!currentVariant.archived" />

    <!-- Buy actions -->
    <ProductSectionsCommonBuyActions v-if="!currentVariant.archived" />

    <ListAssociatedProducts
      v-if="!currentVariant.inStock"
      :product="product"
      type="similar"
      slide-width="160"
      class="mt-10"
    />

    <!-- Extra actions -->
    <ProductSectionsCommonExtraActions
      v-if="currentVariant.inStock && !currentVariant.archived"
    />

    <!-- Special offer banner-->
    <!--    <ProductSectionsCommonSpecialOffer v-if="currentVariant.inStock && !currentVariant.archived" />-->

    <!-- Shipping -->
    <ProductSectionsCommonShipping
      v-if="currentVariant.inStock && !currentVariant.archived"
    />

    <!-- Payment -->
    <ProductSectionsCommonPayment
      v-if="currentVariant.inStock && !currentVariant.archived"
    />

    <!-- Refund -->
    <ProductSectionsCommonRefund
      v-if="currentVariant.inStock && !currentVariant.archived"
    />

    <!-- Articles -->
    <LazyProductSectionsCommonArticles />
    <!-- Experts -->
    <client-only>
      <ListExpertsReviews
        title-class="text-xl font-bold uppercase"
        class="!mt-6"
      />
    </client-only>
  </div>
</template>

<script setup>
import { usePaymentStore } from '~/stores/payment'

const currentVariant = inject('currentVariant')
const product = inject('product')

const router = useRouter()
const onSelectVariant = variant => {
  return variant && router.replace({ params: { slug: variant.slug } })
}

useLazyAsyncData(
  'all-payment-methods',
  () =>
    usePaymentStore().loadAllMethods({
      productCode: currentVariant.value.code
    }),
  {
    server: false,
    watch: [currentVariant],
    deep: true
  }
)

const isLargeScreen = ref(true)

const checkScreenSize = () => {
  if (process.client) {
    isLargeScreen.value = window.innerWidth >= 1024
  }
}

onMounted(() => {
  if (process.client) {
    checkScreenSize()
    window.addEventListener('resize', checkScreenSize)
  }
})

onBeforeUnmount(() => {
  if (process.client) {
    window.removeEventListener('resize', checkScreenSize)
  }
})
</script>

<style lang="postcss" scoped>
.desktop {
  @apply hidden lg:block;
}

.mobile {
  @apply lg:hidden;
}
</style>
