<template>
  <div class="text-sm mt-4">
    <div
      v-if="currentVariant.isOrdered && !currentVariant?.archived"
      class="flex items-center gap-2 text-green-ibis"
    >
      <IconsTagsFreeDelivery class="w-[20px]" />
      <span>{{ t('В дорозі') }}</span>
    </div>
    <div
      v-else-if="currentVariant.inStock && !currentVariant.archived"
      class="flex items-center gap-2"
    >
      <IconsProductAvailabilityCheck class="h-2.25 w-3.5" />
      <span class="text-green-ibis">{{ t('Є в наявності') }}</span>

      <!--      <AppFormSelect
        v-model="city"
        :options="cities"
        :get-label="option => option"
        :get-value="option => option"
        button-class="!text-sm !p-0 !font-normal !pr-9 !leading-6 !link-green-dots"
        chevron-class="!text-green-ibis"
      />-->
    </div>
    <div
      v-if="!currentVariant.inStock && !currentVariant.archived"
      class="flex gap-2 text-gray-mud"
    >
      <IconsProductNotAvailable class="w-3.5" />
      <span class="-mb-0.5">{{ t('Немає в наявності') }}</span>
    </div>
    <div v-if="currentVariant?.archived" class="flex gap-2 text-gray-mud">
      <IconsProductNotAvailable class="w-3.5" />
      <span class="-mb-0.5">{{ t('Виведено з асортименту') }}</span>
    </div>
  </div>
</template>

<script setup>
import uniq from 'lodash/uniq'

const { t } = useI18n()
const currentVariant = inject('currentVariant')
// const city = ref('в Києві')

const cities = computed(() =>
  uniq(
    currentVariant.value?.productInventories
      ?.filter(productInventory => productInventory.inStock)
      .map(productInventory => productInventory?.chainStore?.city?.name)
  )
)

const city = ref(cities?.value?.[0])
</script>
