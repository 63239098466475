<template>
  <section class="space-y-[18px] mt-9">
    <div v-if="brand" class="flex items-center gap-4">
      <AppBrandInfoPopover v-if="brand?.images?.length" :id="brand.getId()">
        <template #target>
          <div class="flex items-center gap-3.5 h-[23px] md:h-[33px]">
            <nuxt-img
              :src="brand.getImagePath()"
              format="webp"
              loading="lazy"
              class="object-contain max-h-full"
            />
          </div>
        </template>
      </AppBrandInfoPopover>
      <span v-else>{{ t('Бренд') }}:</span>
      <nuxt-link
        class="underline"
        :class="brand.slug && 'cursor-pointer'"
        :to="localePath(`${brand.slug ? '/brand/' + brand.slug : ''}`)"
      >
        {{ brand.name }}
      </nuxt-link>
    </div>

    <!--    <ul class="space-y-[18px]" data-copy-protection="0">
      <li v-for="attribute in attributes" :key="attribute.code" data-copy-protection="0">
        {{ attribute.name }}:
        <span class="text-primary" data-copy-protection="0">{{ attribute.value }}</span>
      </li>
    </ul>-->

    <span class="block link-underline w-fit" @click="clickHandler">
      {{ t('Всі характеристики') }}
    </span>
  </section>
</template>

<script setup>
import { useStorage } from '@vueuse/core'

const { t } = useI18n()

const product = inject('product')
const currentVariant = inject('currentVariant')

const attributes = computed(() => {
  return currentVariant.value?.attributes
    ?.filter(({ code }) => !['articul', 'video']?.includes(code))
    ?.slice(0, 3)
})
const brand = computed(() => product.value?.brand || {})

const targetTab = useStorage('target-tab')
const clickHandler = () => {
  targetTab.value = 'specifications'
}

onMounted(() => {
  targetTab.value = null
})
</script>
