<template>
  <ProductListHorizontal
    v-if="products?.length"
    :product-list="products"
    :title="title"
    :base-slide-width="props.slideWidth"
  />
</template>

<script setup>
import { PRODUCT_ASSOCIATION_TYPES } from '~~/utils/constants'
import { useProductsSliderStore } from '~/stores/productsSlider'

const { t } = useI18n()

const props = defineProps({
  product: {
    type: [Object, String],
    default: () => {}
  },
  type: {
    type: String,
    default: ''
  },
  slideWidth: {
    type: [Number, String],
    default: 190
  }
})

const title = computed(() => {
  switch (props.type) {
    case 'related':
      return t('Супутні товари')
    default:
      return t('Схожі товари')
  }
})

const associationType = computed(() => {
  switch (props.type) {
    case 'related':
      return PRODUCT_ASSOCIATION_TYPES.RELATED
    default:
      return PRODUCT_ASSOCIATION_TYPES.SIMILAR
  }
})

const productSliderStore = useProductsSliderStore()

const products = computed(
  () => productSliderStore.productsAssociatedList[associationType.value] || []
)

watch(
  props.product,
  () => {
    productSliderStore.fetchAssociatedProducts(
      props.product?.code,
      associationType.value
    )
  },
  { immediate: true }
)
</script>
