<template>
  <Disclosure
    v-slot="{ open }"
    :default-open="true"
    as="div"
    class="border-b-[1px] border-gray pb-6 mt-2 lg:mt-6"
  >
    <div class="w-full py-2 flex text-[15px] font-bold">
      <span>{{ t('Оплата') }}</span>

      <DisclosureButton as="template" class="">
        <IconsDropdownChevron
          :active="open"
          active-class="!text-gray-mud"
          class="ml-auto"
        />
      </DisclosureButton>
    </div>
    <DisclosurePanel>
      <div class="grid gap-3 lg:grid-cols-2 mt-4">
        <div class="list-item left-col">
          <IconsProductCard class="list-item--icon" />
          <div class="space-y-[5px]">
            <div>{{ t('Карткою онлайн на сайті') }}</div>
            <div class="flex items-center gap-2.5">
              <div class="icon-wrapper">
                <IconsPaymentVisaSmall class="w-5 h-2" />
              </div>
              <div class="icon-wrapper">
                <IconsPaymentMastercardSmall class="w-5 h-3" />
              </div>
              <div class="icon-wrapper">
                <IconsPaymentApple class="w-6.25 h-2.5" />
              </div>
              <div class="icon-wrapper">
                <IconsPaymentGoogle class="w-6.25 h-2.5" />
              </div>
            </div>
          </div>
        </div>

        <div class="list-item left-col">
          <IconsProductCard class="list-item--icon" />
          <span> {{ t('Карткою через термінал у магазинах ІБІС') }} </span>
        </div>

        <div class="list-item left-col">
          <IconsProductWallet class="list-item--icon" />
          <span> {{ t('Накладений платіж у відділенні') }} </span>
        </div>

        <div class="list-item left-col">
          <IconsProductReceipt class="list-item--icon" />
          <div class="space-y-2">
            <span> {{ t('Оплата на розрахунковий рахунок') }} </span>
            <nuxt-link
              :to="localePath('/dostavka-i-oplata/#payment')"
              class="flex items-start gap-2 font-normal link-underline"
            >
              <IconsInfoCircle
                class="h-3.5 mt-0.5 w-min min-w-[14px] max-w-[20px] text-gray-mud hidden lg:block"
              />
              {{ t('Як сплачувати через платіжні сервіси Приват') }}
            </nuxt-link>
          </div>
        </div>
        <div class="list-item right-col lg:row-start-1">
          <IconsPaymentMono class="list-item--icon" />
          <span> {{ t('part_payment_monobank') }} </span>
        </div>

        <div class="list-item right-col lg:row-start-2">
          <IconsPaymentPrivatInstallment class="list-item--icon" />
          <span> {{ t('part_payment_privatbank') }} </span>
        </div>

        <div class="list-item right-col lg:row-start-3 lg:row-span-2">
          <IconsProductIbis class="list-item--icon" />
          <div>
            <div>{{ t('Сертифікатом ІБІС') }}</div>
            <div class="text-gray-mud">
              {{ t('how_pay_using_certificate') }}
            </div>
          </div>
        </div>
        <div class="list-item left-col font-normal">
          <nuxt-link
            :to="localePath('/dostavka-i-oplata/')"
            class="font-normal link-underline"
          >
            {{ t('Детальніше про способи оплати') }}
          </nuxt-link>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

const localePath = useLocalePath()
const { t } = useI18n()
</script>
<style lang="postcss" scoped>
.icon-wrapper {
  @apply flex items-center justify-center px-2.5 h-[25px] bg-gray rounded-[3px];
}

.list-item {
  @apply flex gap-3 text-[11px] lg:text-[12px] min-h-[24px] font-semibold;
}
.list-item--icon {
  @apply w-5 h-min min-w-[20px] h-[20px] text-green-ibis;
}

.left-col {
  @apply lg:col-start-1;
}

.right-col {
  @apply lg:col-start-2 lg:col-span-1;
}
</style>
