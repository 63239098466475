<template>
  <div class="mb-4 lg:mt-9 text-green-swamp">
    <h1
      id="variant-title"
      class="text-sm lg:leading-[29px] mt-3 lg:text-[24px] font-bold"
    >
      {{ parentSeoData?.h1 || seoData?.h1 || currentVariant.name }}
    </h1>
    <div class="flex items-center mt-3">
      <AppReviewRating :rating="product.averageRating" />
      <span
        class="text-xs lg:text-sm underline ml-2 cursor-pointer"
        @click="clickHandler"
      >
        {{ product.totalReviews }} {{ t('відгуків') }}
      </span>
      <span class="text-[10px] lg:text-xs text-gray-mud ml-auto">
        {{ t('Код товару') }}:
        <span id="variant-code">{{ currentVariant.code }}</span>
      </span>
    </div>
  </div>
</template>

<script setup>
import { useStorage } from '@vueuse/core'
import { useSeoStore } from '~/stores/seo'

const { t } = useI18n()
const product = inject('product')
const currentVariant = inject('currentVariant')
const seoData = computed(() => useSeoStore().seoData)
const parentSeoData = inject('seoData', null)

const targetTab = useStorage('target-tab')
const clickHandler = () => {
  targetTab.value = 'reviews'
}

onMounted(() => {
  targetTab.value = null
})
</script>
