<template>
  <div v-if="optionValues?.length > 1">
    <div v-if="props.label" class="text-gray-mud text-sm">
      {{ props.label }}
    </div>
    <template v-if="props.type === 'default'">
      <ProductOptionsOptionDefaultProduct
        v-if="props?.options"
        :value="props?.value"
        :options="optionValues"
        :type="props?.type"
        :label="props?.label"
        class="mt-[18px]"
        @select="onSelect"
      />
    </template>
    <template v-else>
      <div class="flex flex-wrap gap-3 mt-3">
        <ProductOptionsOptionPlatesProduct
          :value="props.value"
          :options="optionValues"
          :type="props.type"
          :label="props.label"
          class="mt-[18px]"
          @select="onSelect"
        />
      </div>
    </template>
  </div>
</template>
<script setup>
import uniqBy from 'lodash/uniqBy'

const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },
  value: {
    type: [Object, String],
    default: () => {}
  },
  label: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'md'
  },
  parentCodes: {
    type: Array,
    default: () => []
  },
  selected: {
    type: Object,
    default: () => {}
  }
})
const emits = defineEmits(['select'])

const optionValues = computed(() => {
  return props.parentCodes?.length
    ? uniqBy(
      props.options?.filter(item => {
        return (
          item &&
            props.parentCodes?.every(option =>
              item?.allOptions?.includes(option)
            )
        )
      }),
      v => v?.code
    )
    : uniqBy(props.options, v => v?.code).filter(v => !!v)
})
const onSelect = payload => emits('select', payload)
</script>
