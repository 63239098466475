import { useAuthStore } from '~/stores/auth'

export default function (variantId, { callback }) {
  const requestOptions = {
    onSuccess: () => callback && callback()
  }
  const watchPrice = () => {
    if (!useAuthStore().loggedIn) {
      return useAuthStore().showAuthDialog(useRoute().fullPath)
    }
    return useApi().productWatchPrice.watchVariant(
      { productVariantCode: variantId },
      requestOptions
    )
  }
  const unwatchPrice = () => {
    return useApi().productWatchPrice.deleteVariant(variantId, requestOptions)
  }

  return { watchPrice, unwatchPrice }
}
