<template>
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.18467 2.57866C8.71242 1.64485 10.4828 1.18505 12.2718 1.25739C14.0609 1.32974 15.7883 1.93097 17.2357 2.98507C18.683 4.03918 19.7853 5.4988 20.4031 7.17936C21.021 8.85993 21.1265 10.686 20.7066 12.4265C20.2866 14.1671 19.3599 15.7441 18.0437 16.958C16.7275 18.1719 15.0808 18.9682 13.312 19.2463C11.5432 19.5244 9.7317 19.2717 8.1065 18.5202C6.4813 17.7687 5.11544 16.5522 4.18164 15.0245L11.9061 10.3031L7.18467 2.57866Z"
      fill="#FDDC00"
    />
    <path
      d="M2.40569 14.7087C1.7643 13.721 1.32373 12.6167 1.10913 11.4587C0.894534 10.3008 0.910109 9.11192 1.15497 7.96001C1.39983 6.80809 1.86918 5.71568 2.53622 4.74515C3.20326 3.77462 4.05493 2.94498 5.0426 2.30359L9.92668 9.82459L2.40569 14.7087Z"
      fill="#10AF0E"
    />
  </svg>
</template>
