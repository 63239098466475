<template>
  <div class="flex gap-3 grow">
    <nuxt-img
      :src="img"
      class="mx-0 object-contain self-start"
      width="26"
      loading="lazy"
    />
    <div class="flex flex-col justify-between md:flex-row gap-3 grow">
      <div class="flex grow flex-col max-w-fit justify-center">
        <span class="inline-block text-xs font-bold mb-1">
          {{ props.item.name }}
        </span>
        <p
          v-if="props.item.description"
          class="text-xs text-gray-mud leading-snug"
          v-html="props.item.description"
        ></p>
      </div>

      <div class="flex items-center justify-between h-fit gap-3">
        <AppFormSelect
          v-model="amountOfPayments"
          :options="props.item.partsInfo"
          :get-label="option => option.partsName"
          :get-value="option => option"
          button-class="border border-gray text-green-swamp w-[200px]"
          button-label-class="text-green-swamp text-xs font-normal mr-[18px]"
          chevron-class="!w-4"
          option-container-class="text-xs w-[200px]"
          listbox-options-class="ring-1 ring-gray"
        />
        <span class="text-sm">{{
          priceFormatter(
            ceil(
              (props.price * amountOfPayments.partsMultiplier) /
                amountOfPayments.partsType,
              2
            )
          )
        }}</span>
      </div>

      <button
        type="button"
        class="flex justify-center md:justify-start items-center h-fit rounded-md px-5 py-2.5 font-bold text-xs text-white uppercase bg-green-ibis gap-2.5 max-w-full md:max-w-fit"
        @click="submitHandler"
      >
        <IconsCheck class="w-3" />
        <span>{{ t('Вибрати') }}</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import ceil from 'lodash/ceil'
const { t } = useI18n()
const priceFormatter = usePriceFormatter()
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  price: {
    type: [String, Number],
    default: 0
  }
})
const emits = defineEmits(['submit'])

const findMiddleOption = () => {
  const options = props.item.partsInfo
  if (!options && !(options instanceof Array)) {
    return ''
  }
  const length = options.length
  const middleIndex = Math.floor(length / 2)
  return length % 2 !== 0 ? options[middleIndex] : options[middleIndex - 1]
}

const amountOfPayments = ref(findMiddleOption())

const img = computed(() => {
  if (props.item?.images.length && props.item?.images[0].path) {
    return useAssetUrl(props.item?.images[0].path)
  }
  return NO_IMAGE_PATH
})

const submitHandler = () => {
  emits('submit', {
    name: props.item.name,
    code: props.item.code,
    amount: amountOfPayments.value
  })
}
</script>
