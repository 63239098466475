<template>
  <svg viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="6.21622"
      cy="7.00003"
      r="6.21622"
      fill="#EC0014"
    />
    <circle
      cx="13.7846"
      cy="7.00003"
      r="6.21622"
      fill="#F79F14"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0008 2.06763C8.52172 3.20397 7.56836 4.99064 7.56836 6.99999C7.56836 9.00934 8.52173 10.796 10.0008 11.9324C11.4799 10.796 12.4332 9.00934 12.4332 6.99999C12.4332 4.99065 11.4799 3.20397 10.0008 2.06763Z"
      fill="#BB0010"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0008 11.9324C11.4799 10.796 12.4332 9.00934 12.4332 6.99999C12.4332 4.99065 11.4799 3.20397 10.0008 2.06763C8.52172 3.20397 7.56836 4.99064 7.56836 6.99999C7.56836 9.00934 8.52173 10.796 10.0008 11.9324Z"
      fill="#BB0010"
    />
  </svg>
</template>
